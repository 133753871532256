<template>
    <div id="app">
        <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
            <div class="container">
                <a class="navbar-brand" href="#">Binance Toplist</a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
                    <ul class="navbar-nav">
                        <li class="nav-item" v-for="p in Object.keys(periods)" :key="p">
                            <a :class="{'nav-link': true, 'active': p === period}" href="#" @click="period=p">{{
                                    p
                                }}</a>
                        </li>
                    </ul>
                    <button class="btn btn-outline-light" type="button" @click="addSymbol()">Add Symbol</button>
                </div>
            </div>
        </nav>
        <div class="container">
            <table class="table">
                <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Symbol</th>
                    <th scope="col">Open</th>
                    <th scope="col">Close</th>
                    <th scope="col">Percent</th>
                    <th scope="col"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(symbol, index) in sortedSymbols" :key="symbol.symbol"
                    :class="{'gaining': symbol.percent && symbol.percent > 0,
                             'losing': symbol.percent && symbol.percent < 0}"
                >
                    <th scope="row">{{ index + 1 }}</th>
                    <td>
                        {{ symbol.symbol }}
                        <div class="spinner-border spinner-border-sm" role="status" v-if="symbol.loading">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <span class="text-danger m-lg-2" v-text="symbol.error" v-if="symbol.error"/>
                    </td>
                    <td>{{ symbol.open ? Number(symbol.open) : '' }}</td>
                    <td>{{ symbol.close ? Number(symbol.close) : '' }}</td>
                    <td>{{ symbol.percent ? symbol.percent.toFixed(2) + '%' : '' }}</td>
                    <td class="text-end">
                        <button class="btn btn-danger btn-sm" @click="deleteSymbol(symbol.symbol)">Delete</button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;

export default {
    name: 'App',
    data: () => ({
        period: '30m',
        periods: {
            '15m': 15 * MINUTE,
            '30m': 30 * MINUTE,
            '1h': HOUR,
            '6h': 6 * HOUR,
            '12h': 12 * HOUR,
            '24h': 24 * HOUR
        },
        symbols: [],
        updating: false
    }),
    computed: {
        sortedSymbols() {
            let symbols = [...this.computedSymbols];

            symbols.sort((a, b) => {
                let aPercent = a.percent || -1000000000;
                let bPercent = b.percent || -1000000000;
                if (aPercent > bPercent) {
                    return -1
                } else if (aPercent < bPercent) {
                    return 1;
                }
                return 0;
            });
            return symbols;
        },
        computedSymbols() {
            return this.symbols.map(s => {
                const symbol = s.symbol;
                const error = s.error;
                const loading = s.loading;
                const start = s.start ? this.unpackKline(s.start) : undefined;
                const end = s.end ? this.unpackKline(s.end) : undefined;

                const open = start ? start.open : undefined;
                const close = end ? end.close : undefined;
                const percent = (open && close) ? (100 * (close - open) / open) : undefined;

                return {
                    symbol,
                    open,
                    close,
                    percent,
                    error,
                    loading
                }
            });
        }
    },
    methods: {
        unpackKline(input) {
            let [openTime, open, high, low, close, volume, closeTime, quoteAssetVolume, numberOfTrades, takerBuyBaseAssetVolume, takerBuyQuoteAssetVolume, ignore] = input;
            return {
                openTime,
                open,
                high,
                low,
                close,
                volume,
                closeTime,
                quoteAssetVolume,
                numberOfTrades,
                takerBuyBaseAssetVolume,
                takerBuyQuoteAssetVolume,
                ignore
            }
        },
        async get(path, parameters) {
            let url = new URL(`https://cors-anywhere.expressware.co/https://api.binance.com${path}`);
            url.search = new URLSearchParams(parameters).toString();
            let response = await fetch(url.toString());
            return await response.json();
        },
        deleteSymbol(symbol) {
            this.symbols = this.symbols.filter(s => s.symbol !== symbol);
            this.save();
        },
        addSymbol() {
            let symbol = window.prompt('Enter symbol name');
            if (symbol) {
                this.symbols.push({
                    symbol,
                    loading: false,
                    error: null,
                    lastUpdate: 0,
                    start: null,
                    end: null
                });
                this.deduplicate();
                this.save();
            }
        },
        deduplicate() {
            let symbols = [];
            this.symbols.forEach(symbol => {
                if (!symbols.find(s => s.symbol === symbol.symbol)) {
                    symbols.push(symbol);
                }
            });
            this.symbols = symbols;
        },
        async updateSymbol(symbol) {
            try {
                symbol.loading = true;
                let now = (new Date()).getTime();
                let start = await this.get('/api/v3/klines', {
                    symbol: symbol.symbol,
                    interval: '1m',
                    limit: 1,
                    startTime: now - this.periods[this.period]
                });
                if (start.msg) {
                    symbol.error = start.msg;
                } else {
                    let end = await this.get('/api/v3/klines', {
                        symbol: symbol.symbol,
                        interval: '1m',
                        limit: 1,
                        startTime: now - MINUTE
                    });
                    symbol.error = null;
                    symbol.start = start[0];
                    symbol.end = end[0];
                }
            }finally{
                symbol.loading = false;
            }
        },
        async updateData() {
            if (this.updating) {
                return;
            }
            try {
                this.updating = true;
                for (const symbol of this.symbols) {
                    let now = (new Date()).getTime();
                    let age = now - symbol.lastUpdate;
                    if (age > MINUTE) {
                        this.updateSymbol(symbol);
                        symbol.lastUpdate = now;
                    }
                }
            } finally {
                this.updating = false;
            }
        },
        save() {
            let symbols = this.symbols.map(s => s.symbol);
            window.localStorage.setItem('symbols', JSON.stringify(symbols));
            window.localStorage.setItem('period', this.period);
        },
        load() {
            try {
                let period = window.localStorage.getItem('period');
                if (this.periods[period]) {
                    this.period = period;
                }
                let symbols = window.localStorage.getItem('symbols');
                if (symbols) {
                    symbols = JSON.parse(symbols);
                    this.symbols = symbols.map(symbol => ({
                        symbol,
                        loading: false,
                        error: null,
                        lastUpdate: 0,
                        start: null,
                        end: null
                    }));
                    this.deduplicate();
                }
                // eslint-disable-next-line no-empty
            } catch {
            }
        }
    },
    created() {
        this.load();
        setInterval(() => {
            this.updateData();
        }, 1000);
        this.$nextTick(() => this.updateData());
    },
    watch: {
        period() {
            this.save();
            this.symbols = this.symbols.map(symbol => ({
                symbol: symbol.symbol,
                loading: false,
                error: null,
                lastUpdate: 0,
                start: null,
                end: null
            }));
            this.$nextTick(() => this.updateData());
        }
    }
}
</script>

<style>
td, th {
    vertical-align: middle;
}

tr.gaining {
    background: rgba(0, 255, 0, 0.2);
}

tr.losing {
    background: rgba(255, 0, 0, 0.2);
}
</style>